// @flow

import {Table, TableBody, TableCell, TableRow, withStyles, withTheme} from "@material-ui/core";
import React from "react";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {Headline1} from "../../../Components/Basic/Headlines";
import {HostList} from "./HostList";


const styles = theme => ({
    headline: {
        display: "flex",
        justifyContent: "flex-start",
        marginLeft: "1.25rem"
    },
    drawerDiv: {
        width: "43.75rem"
    }
});

type Props = {
    portLists: Array<GSPPortList>,
    credentials: Array<GSPCredentialsEntity>,
    target: GSPTarget;
    classes: typeof styles;
    theme: any;
    t: any;
}

class _TargetInformation extends React.Component<Props, any> {

    getCredentialByType = (target: GSPTarget, credentialsList: Array<GSPCredentialsEntity>, type: string): string => {
        const {t} = this.props;
        if (!target.credentials) {
            return t("targetInformation.noInformation");
        }
        let credentials = target.credentials[type];
        if (!credentials) {
            return t("targetInformation.noInformation");
        }
        let credential = credentialsList.find(c => c.id === credentials.id);
        if (!credential) {
            return t("targetInformation.noInformation");
        }
        return credential.name;
    };


    displayCredentials = (credentials: ?GSPCredentialsRef) => {
        const {t} = this.props;
        if (!credentials) {
            return (t("target.details.withoutCredentials"));
        }
        return (credentials.name);

    };

    render() {
        const {target, t, classes} = this.props;

        return (
            <div className={classes.drawerDiv}>
                <div className={classes.headline}>
                    <Headline1> {t("target.details.information", {name: this.props.target.name})}</Headline1>
                </div>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                {t("target.details.targetName")}
                            </TableCell>
                            <TableCell>
                                {target.name}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {t("target.details.host")}
                            </TableCell>
                            <TableCell>
                                <HostList
                                    includedHosts={target.includedHosts}
                                    excludedHosts={target.excludedHosts}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {t("target.details.portList")}
                            </TableCell>
                            <TableCell>
                                {target.portList.name}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t("target.details.isInternal")}</TableCell>
                            <TableCell>
                                {target.isInternal ? <>{t("yes")}</> : <>{t("no")}</>}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2} style={{fontWeight: "bold"}}>
                                {t("target.details.credentials")}
                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {"SSH"}
                            </TableCell>
                            <TableCell>
                                {this.displayCredentials(target.credentials["SSH"])}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {"SSH Port"}
                            </TableCell>
                            <TableCell>
                                {target.port ? target.port : null}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {"SMB"}
                            </TableCell>
                            <TableCell>
                                {this.displayCredentials(target.credentials["SMB"])}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{"ESXI"}</TableCell>
                            <TableCell>
                                {this.displayCredentials(target.credentials["ESXI"])}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Kerberos</TableCell>
                            <TableCell>
                                {this.displayCredentials(target.credentials["KRB5"])}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        );
    }
}

export const TargetInformation = compose(
    withStyles(styles),
    withTranslation(),
    withTheme
)(_TargetInformation);
