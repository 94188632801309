import React, {useState} from 'react'
import {Col, Row} from "reactstrap";
import {Input} from "@greenbone/cloud-component-library"
import {useTranslation} from "react-i18next";
import {ChipInput} from "../../../StyledComponents/Form/ChipInput";

export const KerberosInputs = ({login, password, kerberos_realm, kdc, onChange, fieldStatus, isFieldStatusValid}) => {
    const {t} = useTranslation()

    const [internalKdc, setInternalKdc] = useState(kdc ? kdc.split(',') : [])

    const changeKdc = (newKdc: string[]) => {
        setInternalKdc(newKdc)

        const event = {
            target: {
                name: 'kdc',
                value: newKdc.join(',')
            }
        }
        onChange(event)
    }

    const addKdc = (val) => {
        if (!val) {
            return
        }

        if (val.indexOf(',') >= 0) {
            val = val.split(',')
        } else {
            val = [val]
        }

        const newKdc = [
            ...internalKdc,
            ...val
        ]

        changeKdc(newKdc)
    }

    const deleteKdc = (val, index) => {
        const newKdc = [
            ...internalKdc.slice(0, index),
            ...internalKdc.slice(index + 1),
        ]

        changeKdc(newKdc)
    }

    return <React.Fragment>
        <Row>
            <Col>
                <Input
                    name="login"
                    value={login || ""}
                    onChange={onChange}
                    isValid={fieldStatus.login}
                    label={t("common.action.login")}
                    helperText={!isFieldStatusValid(fieldStatus.login) ? fieldStatus.login : ""}
                />
            </Col>
            <Col>
                <Input
                    type="password"
                    name="password"
                    value={password || ""}
                    onChange={onChange}
                    isValid={fieldStatus.password}
                    label={t("credentials.components.edit.password")}
                    helperText={!isFieldStatusValid(fieldStatus.password) ? fieldStatus.password : ""}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <Input
                    style={{marginTop: '12px'}}
                    name="kerberos_realm"
                    value={kerberos_realm || ""}
                    onChange={onChange}
                    isValid={fieldStatus.realm}
                    label={t("credentials.components.edit.realm")}
                    helperText={!isFieldStatusValid(fieldStatus.realm) ? fieldStatus.realm : ""}
                />
            </Col>
            <Col>
                <ChipInput
                    name="kdc"
                    value={internalKdc}
                    onBlur={(event) => {
                        addKdc(event.target.value)
                    }}
                    style={{width: "100%"}}
                    onAdd={addKdc}
                    onDelete={deleteKdc}
                    newChipKeyCodes={[32, 188, 113, 9, 13]}
                    label={t("credentials.components.edit.kdc")}
                    helperText={t("common.targetCreate.separation")}
                    isValid={fieldStatus.kdc}
                />
            </Col>
        </Row>
    </React.Fragment>
}
