import {buildUrl} from "../buildUrl"
import {http, HttpResponse} from "msw"


export const userManagementServiceHandler = [
    http.get(buildUrl("/usermanagement/group/settings"), () => {
        return new HttpResponse(
            {adminReportAccessEnabled: true, adminJumpInEnabled: true},
            {
                status: 200
            }
        )
    }),
    http.put(buildUrl("/usermanagement/group/settings"), () => {
        return new HttpResponse(
            {asd: "asd"},
            {
                status: 200
            }
        )
    })

    // rest.get((`http://localhost:3000/usermanagement/public/jumpIn/login/abc`), ((req, res, context) => {
    //     return res(
    //         context.status(200),
    //         context.json({
    //             accessToken: "string",
    //             refreshToken: "string"
    //         })
    //     )
    // }))

]
