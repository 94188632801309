import {buildUrl} from "../buildUrl"
import {http, HttpResponse} from "msw"


export const taskExecutionHandler = [
    http.delete(buildUrl("/task_execution/3451"), () => {
        return new HttpResponse(
            {},
            {
                status: 404
            }
        )
    }),

    http.put(buildUrl("/task_execution/5170"), () => {
        return new HttpResponse(
            {},
            {
                status: 404
            }
        )
    })

]
