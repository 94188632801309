import {http, HttpResponse} from "msw"
import Chance from "chance"
import {buildUrl} from "../buildUrl"

const chance = new Chance(Math.random)

const output = [{
    "id": 9543,
    "name": "IPV6",
    "comment": "",
    "includedHosts": [{"id": 9544, "validationStatus": "APPROVED", "representation": "2a03:b0c0:3:d0::12fd:8001"}],
    "excludedHosts": [],
    "aliveTest": {"id": 2, "name": "ICMP"},
    "portList": {"id": 1, "name": "ALL IANA ASSIGNED TCP"},
    "credentials": {"SMB": {"id": 2323, "name": "IPV6"}, "ESXI": {"id": 2323, "name": "IPV6"}},
    "sshPort": null,
    "ipAddressAmount": 1,
    "isInternal": false,
    "targetType": "IP"
}, {
    "id": 4475,
    "name": "Test",
    "comment": "",
    "includedHosts": [{"id": 5735, "validationStatus": "APPROVED", "representation": "144.126.244.58"}],
    "excludedHosts": [],
    "aliveTest": {"id": 2, "name": "ICMP"},
    "portList": {"id": 1, "name": "ALL IANA ASSIGNED TCP"},
    "credentials": {"SSH": {"id": 2612, "name": "Test"}},
    "sshPort": 22,
    "ipAddressAmount": 1,
    "isInternal": false,
    "targetType": "IP"
}, {
    "id": 14142,
    "name": "13",
    "comment": "",
    "includedHosts": [{"id": 14143, "validationStatus": "REQUESTED", "representation": "8.8.8.8"}],
    "excludedHosts": [],
    "aliveTest": {"id": 2, "name": "ICMP"},
    "portList": {"id": 1, "name": "ALL IANA ASSIGNED TCP"},
    "credentials": {},
    "sshPort": null,
    "ipAddressAmount": 1,
    "isInternal": false,
    "targetType": "IP"
}, {
    "id": 14942,
    "name": "fdsf",
    "comment": "",
    "includedHosts": [{"id": 14943, "validationStatus": "REQUESTED", "representation": "0.0.0.0"}],
    "excludedHosts": [],
    "aliveTest": {"id": 2, "name": "ICMP"},
    "portList": {"id": 1, "name": "ALL IANA ASSIGNED TCP"},
    "credentials": {},
    "sshPort": null,
    "ipAddressAmount": 1,
    "isInternal": false,
    "targetType": "IP"
},
    {
        "id": 14943,
        "name": "hostname",
        "comment": "",
        "includedHosts": [{"id": 14943, "validationStatus": "REQUESTED", "representation": "hackerman.cloud"}],
        "excludedHosts": [],
        "aliveTest": {"id": 2, "name": "ICMP"},
        "portList": {"id": 1, "name": "ALL IANA ASSIGNED TCP"},
        "credentials": {},
        "sshPort": null,
        "ipAddressAmount": 1,
        "isInternal": false,
        "targetType": "HOSTNAME"
    }

]

export const targetServiceHandler = [
    http.get(buildUrl("/targets"), () => {
        return new HttpResponse(
            output,
            {
                status: 200
            }
        )
    }),

    http.post(buildUrl("/targets"), () => {
        return new HttpResponse(
            {
                fieldErrors: {
                    "includedHosts": ["An error occured"]
                }
            },
            {
                status: 400
            }
        )
    }),

    http.get(buildUrl("/targets/14943"), () => {
        return new HttpResponse(
            {
                "id": 14943,
                "name": "fdsf",
                "comment": "",
                "includedHosts": [{"id": 14943, "validationStatus": "REQUESTED", "representation": "hackerman.cloud"}],
                "excludedHosts": [],
                "aliveTest": {"id": 2, "name": "ICMP"},
                "portList": {"id": 1, "name": "ALL IANA ASSIGNED TCP"},
                "credentials": {},
                "sshPort": null,
                "ipAddressAmount": 1,
                "isInternal": false,
                "targetType": "HOSTNAME"
            },
            {
                status: 200
            }
        )
    })

]
