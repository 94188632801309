//@flow

import React, {useState} from "react"
import {useTranslation} from "react-i18next"
import styled from "styled-components"
import InfoIconSVG from "../../../../assets/icons/scan-management/information-circle-grey.svg?url"
import SlideIn from "../../../Components/Dialogs/SlideIn"
import {Subheadline} from "../../../Components/Font/Font"


export const InfoIcon = styled.div`
  background-image: url(${InfoIconSVG});
  background-repeat: no-repeat;
  height: 1.56rem;
  width: 1.56rem;
  margin-left: 0.63rem;
  margin-top: 0.31rem;

  &:hover {
    cursor: pointer;
  }
`;

export const ScanLimitInformation = () => {
    const {t} = useTranslation();

    const [open, setOpen] = useState(false)

    const handleCloseSlide = () => {
        setOpen(false)
    };

    const handleOpenSlide = () => {
        setOpen(true)
    };

    return [
        <InfoIcon key={0} onClick={handleOpenSlide}/>,
        <SlideIn key={1} open={open} onClose={handleCloseSlide}>
            <div style={{maxWidth: "31.25rem"}}>
                <Subheadline>
                    {t("scanLimit.headline")}
                </Subheadline>
                {t("scanLimit.info")}
            </div>

        </SlideIn>,
    ];
}
