//@flow

import React from "react";
import {Trans} from "react-i18next";


export const TaskExecutionStatusMapper = {
    AVAILABLE: {
        value: "AVAILABLE",
        text: <Trans i18nKey={"scanmanagement.AVAILABLE"}>Verfügbar</Trans>,
        style: "stateColorGreen5",
    },
    EXPORT_REQUESTED: {
        value: "EXPORT_REQUESTED",
        text: <Trans i18nKey={"scanmanagement.EXPORT_REQUESTED"}>Export angefordert</Trans>,
        style: "stateColorGreen1",
    },
    EXPORTED: {
        value: "EXPORTED",
        text: <Trans i18nKey={"scanmanagement.EXPORTED"}>Exportiert</Trans>,
        style: "stateColorGreen2",
    },
    EXPORT_FAILED: {
        value: "EXPORT_FAILED",
        text: <Trans i18nKey={"scanmanagement.EXPORT_FAILED"}>Export fehlgeschlagen</Trans>,
        style: "stateColorRed1",
    },
    REQUESTED: {
        value: "REQUESTED",
        text: <Trans i18nKey={"scanmanagement.REQUESTED"}>Scan angefordert</Trans>,
        style: "stateColorGreen3",
    },
    RUNNING: {
        value: "RUNNING",
        text: <Trans i18nKey={"scanmanagement.RUNNING"}>Laufend</Trans>,
        style: "stateColorGreen4",
    },
    STOP_REQUESTED: {
        value: "STOP_REQUESTED",
        text: <Trans i18nKey={"scanmanagement.STOP_REQUESTED"}>Stop angefordert</Trans>,
        style: "stateColorYellow1",
    },
    STOPPED: {
        value: "STOPPED",
        text: <Trans i18nKey={"scanmanagement.STOPPED"}>Gestoppt</Trans>,
        style: "stateColorYellow2",
    },
    DONE: {
        value: "DONE",
        text: <Trans i18nKey={"scanmanagement.DONE"}>Abgeschlossen</Trans>,
        style: "stateColorBlue1",
    },
    INTERNAL_ERROR: {
        value: "INTERNAL_ERROR",
        text: <Trans i18nKey={"scanmanagement.INTERNAL_ERROR"}>Interner Fehler</Trans>,
        style: "stateColorRed2",
    },
    NEW: {
        value: "NEW",
        text: <Trans i18nKey={"scanmanagement.NEW"}>Neu und verfügbar</Trans>,
        style: "stateColorGreen5",
    },
    DELETE_REQUESTED: {
        value: "DELETE_REQUESTED",
        text: <Trans i18nKey={"scanmanagement.DELETE_REQUESTED"}>Löschen angefordert</Trans>,
        style: "stateColorGreen6",
    },
    LIMIT_EXCEED: {
        value: "LIMIT_EXCEED",
        text: <Trans i18nKey={"scanmanagement.LIMIT_EXCEED"}>Gleichzeitiger Limit erreicht</Trans>,
        style: "stateColorRed2",
    },
};

export type TaskExecutionStatus = $Values<typeof TaskExecutionStatusMapper>;
