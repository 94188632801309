import React from "react";
import {Trans} from "react-i18next";
import {TabBox} from "./TabBox";


type Props = {
    credential: GSPCredentialsDto;
    onClick: EventCallback;
}

const type = {
    PASSWORD: <Trans i18nKey={"credentials.components.edit.LoginAndPassword"}/>,
    KEYFILE: <Trans i18nKey={"credentials.components.edit.LoginPassphraseAndPrivateKey"}/>,
    KERBEROS: <Trans i18nKey={"credentials.components.edit.Kerberos"}/>
};

export function CredentialTabBox(props: Props) {
    const {credential, onClick, ...others} = props;

    return (
        <TabBox title={credential.name} onClick={() => onClick(credential.id)} {...others}>
            {type[credential.loginType]}
        </TabBox>
    );
}
