import {http, HttpResponse} from "msw"
import {buildUrl} from "../buildUrl"

const jsonBody = [
    {
        "version": "2020.02.51",
        "date": "2021-03-24T13:27:13.194Z",
        "changed": [
            {
                "type": "NEW_FEATURE",
                "message": {
                    "de": "DE Better lore ipsum",
                    "en": "en Better lore ipsum"
                }
            },
            {
                "type": "NEW_FEATURE",
                "message": {
                    "de": "DE asdasd",
                    "en": "en Basdasdasdsum"
                }
            }
        ]
    },
    {
        "version": "2020.03.01",
        "date": "2021-03-24T13:27:13.194Z",
        "changed": [
            {
                "type": "NEW_FEATURE",
                "message": {
                    "de": "DE Better lore ipsum",
                    "en": "en Better lore ipsum"
                }
            },
            {
                "type": "NEW_FEATURE",
                "message": {
                    "de": "DE Better lore ipsum",
                    "en": "en Better lore ipsum"
                }
            }
        ]
    },
    {
        "version": "2020.02.50",
        "date": "2021-03-22T13:27:13.194Z",
        "changed": [
            {
                "type": "NEW_FEATURE",
                "message": {
                    "de": "DE Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam",
                    "en": "en Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam"
                }
            },
            {
                "type": "BUGFIX",
                "message": {
                    "de": "DE Better lore ipsum",
                    "en": "en Better lore ipsum"
                }
            },
            {
                "type": "NEW_FEATURE",
                "message": {
                    "de": "DE Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam",
                    "en": "en Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam"
                }
            }
        ]
    },
    {
        "version": "2020.03.05",
        "date": "2021-03-24T13:27:13.194Z",
        "changed": [
            {
                "type": "NEW_FEATURE",
                "message": {
                    "de": "DE Better lore ipsum",
                    "en": "en Better lore ipsum"
                }
            },
            {
                "type": "NEW_FEATURE",
                "message": {
                    "de": "DE asdasd",
                    "en": "en Basdasdasdsum"
                }
            }
        ]
    },
    {
        "version": "2020.01.01",
        "date": "2021-01-02T13:27:13.194Z",
        "changed": [
            {
                "type": "IMPROVEMENT",
                "message": {
                    "de": "DE Better lore ipsum",
                    "en": "en Better lore ipsum"
                }
            },
            {
                "type": "BUGFIX",
                "message": {
                    "de": "DE Better lore ipsum",
                    "en": "en Better lore ipsum"
                }
            }
        ]
    }
]

export const productUpdateService = [
    http.get(buildUrl("/ui/manual/changes.json"), () => {

        return new HttpResponse(
            jsonBody,
            {
                status: 200
            }
        )
    })
]
