import {IconButton, LinearProgress, Typography, withStyles, withTheme} from "@material-ui/core"
import {Description, Stop} from "@material-ui/icons"
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline"

import React from "react"
import {Trans, withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import {bindActionCreators, compose} from "redux"
import {openAlertDialog} from "../../../Components/Dialogs/AlertDialog/actions"
import {Callout, Tooltip} from "../../../Components/Dialogs/Callout"
import ExpansionPanelRow from "../../../Components/Dialogs/SlideIn/ExpansionPanelRow"
import StateLabel from "../../../Components/Label/StateLabel"
import Loader from "../../../Components/Loader/Loader"
import {openErrorSnackbar} from "../../../Components/Snackbar/actions"
import {DateTimeFormatter} from "../../../controller/DateTimeFormatter"
import {Logger} from "../../../controller/loggingController"
import {Parser} from "../../../Helper/Parser"
import {Value} from "../../../Helper/Validator"
import {SIODateTime, Snackbar} from "@greenbone/cloud-component-library"
import {TaskExecutionRestApiClient} from "../../../services/apiClients/TaskExecution/TaskExecutionRestApiClient"
import {
    FailedDependencyError,
    ForbiddenError,
    NotFoundError,
    TaskStartErrors,
    TaskStopErrors
} from "../../../services/Exceptions"
import {TargetInformation} from "../../Target/components/TargetInformation"
import {TargetInformationWrapper} from "../../Target/components/TargetInformationWrapper"
import {ReportDownloadLink} from "../../Reporting/components/ReportDownloadLink"
import {ExportFailedInformation} from "./ExportFailedInformation"
import {ReportTable} from "./ReportTable"

import type {TaskExecutionStatus} from "./TaskExecutionStatus"
import {TaskInformation} from "./TaskInformation"
import {ReportNavigationUrlProvider} from "../../Reporting/ReportNavigationUrlProvider"
import {DynamicTableCell, DynamicTableRow} from "../../Task/components/Table/DynamicTable"
import {TaskContext} from "../TaskContext"
import {ScanLimitInformation} from "./ScanLimitInformation";

const styles = theme => ({
    root: {
        height: "100%",
        marginTop: theme.spacing(3)
    },
    center: {
        textAlign: "center"
    },
    logHeader: {
        width: "10%"
    }
})

type Props = {
    task: GSPTask;
    +openAlertDialog: typeof openAlertDialog;
    +openErrorSnackbar: typeof openErrorSnackbar;
    classes: typeof styles;
    hiddenTasks: Array<string>;
    reportCount: number;
    latestReportId: ?number;
    lastScan: SIODateTime;
    taskExecutionStatus: TaskExecutionStatus;
    progress: number;
    statusInformation: ?string;
    t: any;
}

type State = {
    _exception: any,
    _loading: any,
}

class _DashboardTaskEntry extends React.Component<Props, State> {
    taskExecutionRestApiClient: TaskExecutionRestApiClient

    state = {
        _loading: false,
        _exception: null
    }

    constructor(props) {
        super(props)
        this.taskExecutionRestApiClient = new TaskExecutionRestApiClient()
    }

    renderReports() {
        return <ReportTable task={this.props.task}/>
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props, nextContext: Props) {
        if (nextProps.taskExecutionStatus && nextProps.taskExecutionStatus.value !== this.props.taskExecutionStatus.value) {
            this.setState({_loading: false})
        }
    }

    handleStartTask = () => {
        const {t} = this.props

        Logger.debug(`Start task with id:'${this.props.task.id}'`)
        this.setState({_loading: true})
        if (this.context.setSending) {
            this.context.setSending()
        }

        this.taskExecutionRestApiClient.startTask(this.props.task.id)
            .catch(error => {
                this.setState({
                    _loading: false
                })

                if (error.status) {
                    if (error.status >= 400 && error.status < 600) {
                        Snackbar.Error(error)
                        return
                    }
                }

                if (error.type === ForbiddenError) {
                    Snackbar.Error(error.message)
                } else if (error.type === NotFoundError) {
                    Snackbar.Error(t("errors.taskNotFound"))
                } else if (Value(error.type).isInList(TaskStartErrors)) {
                    Snackbar.Error(error.message)
                } else if (error.type === FailedDependencyError) {
                    Snackbar.Error(t("scanmanagement.noValidated"))
                } else {
                    this.setState({
                        _exception: error
                    })
                }
            })
    }

    handleStopTask = () => {
        const {t} = this.props
        this.setState({_loading: true})

        this.taskExecutionRestApiClient.stopTask(this.props.task.id)
            .catch(error => {
                this.setState({
                    _loading: false
                })
                if (error.type === ForbiddenError) {
                    Snackbar.Error(error.message)
                } else if (Value(error.type).isInList(TaskStopErrors)) {
                    this.props.openAlertDialog(t("common.messages.error"), error.message)
                } else {
                    this.setState({_exception: error})
                }
            })
    }

    renderTaskButton() {
        const {t} = this.props

        if (this.state._loading) {
            return <Loader type={"INLINE"}/>
        }
        //statusInformation
        const TaskActionableStatus = {
            STARTABLE: "STARTABLE",
            NOT_STARTABLE: "NOT_STARTABLE",
            STOPPABLE: "STOPPABLE",
            NOT_STOPPABLE: "NOT_STOPPABLE"
        }


        if (!this.props.task.executable) {
            return <Tooltip id={"GSP-START-BUTTON-DISABLED_TASK_SERVICE"} type={"warning"} tooltip={(
                <div>
                    <Typography
                        variant="subtitle1">{t("scanmanagement.couldNotStart")}</Typography>
                    <Trans i18nKey={"scanmanagement.validateInformation"}>
                        Stellen Sie sicher, dass alle Host-Einträge für die Analyse verifiziert
                        sind <br/> und die Entitäten für die Analyse vollständig sind.
                    </Trans>

                </div>
            )}>
                <IconButton onClick={this.handleStartTask} disabled={true}>
                    <PlayCircleOutlineIcon/>
                </IconButton>
            </Tooltip>
        }

        switch (this.props.transition) {
            case TaskActionableStatus.STARTABLE : {
                return (
                    <Tooltip id={"START-THE-TASK"} tooltip={(
                        <div>
                            <Typography
                                variant="subtitle1">{t("scanmanagement.start")}</Typography>
                            <Trans i18nKey={"scanmanagement.startDescription"}>
                                Starten Sie die Scan-Aufgabe
                            </Trans>

                        </div>
                    )}>
                        <IconButton disabled={this.context._sending} onClick={this.handleStartTask}>
                            <PlayCircleOutlineIcon/>
                        </IconButton>
                    </Tooltip>
                )
            }
            case TaskActionableStatus.STOPPABLE : {
                return <Tooltip id={"STOP-THE-TASK"} tooltip={(
                    <div>
                        <Typography
                            variant="subtitle1">{t("scanmanagement.stop")}</Typography>
                        <Trans i18nKey={"scanmanagement.stopDescription"}>
                            Stoppen Sie die Scan-Aufgabe.
                        </Trans>
                    </div>
                )}>
                    <IconButton onClick={this.handleStopTask}><Stop/></IconButton>
                </Tooltip>
            }
            case TaskActionableStatus.NOT_STOPPABLE : {
                return <Tooltip type={"warning"} id={"STOP-THE-TASK-DISABLED"} tooltip={(
                    <div>
                        <Typography
                            variant="subtitle1">{t("scanmanagement.stopDisabled")}</Typography>
                        {this.props.statusInformation}
                    </div>
                )}>
                    <IconButton disabled={true}><Stop/></IconButton>
                </Tooltip>
            }
            case TaskActionableStatus.NOT_STARTABLE : {
                return <Callout tooltip={(
                    <div>
                        <Typography
                            variant="subtitle1">{t("scanmanagement.couldNotStart")}</Typography>
                        {this.props.statusInformation}

                    </div>
                )}>
                    <IconButton onClick={this.handleStartTask} disabled={true}>
                        <PlayCircleOutlineIcon/>
                    </IconButton>
                </Callout>
            }
        }


        if (this.props.task.executable) {
            return (
                <Tooltip id={"START-THE-TASK2"} tooltip={(
                    <div>
                        <Typography
                            variant="subtitle1">{t("scanmanagement.start")}</Typography>
                        <Trans i18nKey={"scanmanagement.startDescription"}>
                            Starten Sie die Scan-Aufgabe
                        </Trans>

                    </div>
                )}>
                    <IconButton disabled={this.context._sending} onClick={this.handleStartTask}>
                        <PlayCircleOutlineIcon/>
                    </IconButton>
                </Tooltip>
            )
        } else {
            return (
                <Tooltip id={"START-THE-TASK-DISABLED2"} tooltip={(
                    <div>
                        <Typography
                            variant="subtitle1">{t("scanmanagement.couldNotStart")}</Typography>
                        <Trans i18nKey={"scanmanagement.validateInformation"}>
                            Stellen Sie sicher, dass alle Host-Einträge für die Analyse verifiziert
                            sind <br/> und die Entitäten für die Analyse vollständig sind.
                        </Trans>

                    </div>
                )}>
                    <IconButton onClick={this.handleStartTask} disabled={true}>
                        <PlayCircleOutlineIcon/>
                    </IconButton>
                </Tooltip>
            )
        }

    }

    renderProgress(progress) {
        const {classes, t} = this.props
        let infoText = t("scanmanagement.analyseTarget")

        if ((progress !== undefined && progress !== null)) {
            if (progress <= 2) {
                return <div>
                    <LinearProgress variant="indeterminate"
                                    value={progress}
                    />
                    <div className={classes.center}>
                        {infoText}
                    </div>
                </div>
            } else if (progress <= 96) {
                return <div>
                    <LinearProgress variant="buffer"
                                    value={progress}
                                    valueBuffer={progress}
                    />
                    <div className={classes.center}>
                        {progress}%
                    </div>
                </div>
            } else if (progress <= 100) {
                infoText = t("scanmanagement.importResults")
                return <div>
                    <LinearProgress variant="indeterminate"
                                    value={progress}
                    />
                    <div className={classes.center}>
                        {infoText}
                    </div>
                </div>
            }
        }
        return ""


    }

    render() {
        const {task, lastScan, reportCount, latestReportId, t, lastTaskExecutionId, isDownloadable} = this.props

        if (this.state._exception) {
            throw this.state._exception
        }
        const progress = this.props.progress ? Parser.parseInt(this.props.progress) : 0.0

        return (
            <DynamicTableRow key={task.id}>
                <DynamicTableCell style={{width: "3.13rem", padding: 0, paddingLeft: ".5rem"}}>
                    {this.renderTaskButton()}
                </DynamicTableCell>
                <DynamicTableCell style={{width: "3.13rem", padding: 0, textAlign: "center"}}>
                    {
                        this.props.latestReportId ? <Tooltip id={"JUMP-INTO-NEW-REPORT"} tooltip={(
                                <div>
                                    <Typography
                                        variant="subtitle1">{t("scanmanagement.report")}</Typography>
                                    <Trans i18nKey={"scanmanagement.reportDescription"}>
                                        Springen Sie direkt in den neusten Report.
                                    </Trans>

                                </div>
                            )}>
                                <Link to={{
                                    pathname: ReportNavigationUrlProvider.Dashboard(),
                                    search: `?${new URLSearchParams({
                                        task: task.id,
                                        report: latestReportId
                                    }).toString()}`
                                }}>
                                    <IconButton>
                                        <Description/>
                                    </IconButton>
                                </Link>
                            </Tooltip>
                            :
                            "-"
                    }
                </DynamicTableCell>
                <ExpansionPanelRow element={DynamicTableCell} data={(
                    <TaskInformation task={task}/>)}>
                    {task.name}
                </ExpansionPanelRow>
                <DynamicTableCell>
                    <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                        {this.props.taskExecutionStatus ?
                            <StateLabel colorState={this.props.taskExecutionStatus.style}>
                                {this.props.taskExecutionStatus.text}
                            </StateLabel>
                            : ""
                        }
                        {(this.props.taskExecutionStatus.value === "EXPORT_FAILED") && (
                            <ExportFailedInformation status={this.props.statusInformation}/>
                        )}
                        {(this.props.taskExecutionStatus.value === "LIMIT_EXCEED") && (
                            <ScanLimitInformation />
                        )}
                    </div>
                </DynamicTableCell>
                <DynamicTableCell>
                    {
                        this.props.taskExecutionStatus.value === "RUNNING" &&
                        this.renderProgress(progress)
                    }
                </DynamicTableCell>
                <ExpansionPanelRow
                    element={DynamicTableCell}
                    title={task.name}
                    data={this.renderReports()}
                >
                    {reportCount}
                </ExpansionPanelRow>
                <DynamicTableCell>
                    {
                        (lastScan) ?
                            <nobr>{DateTimeFormatter.toDateTimeString(lastScan)}</nobr> : ""
                    }
                </DynamicTableCell>
                <ExpansionPanelRow element={DynamicTableCell} data={(
                    <TargetInformationWrapper targetId={task.target.id}>
                        <TargetInformation/>
                    </TargetInformationWrapper>
                )}>
                    {task.target.name}
                </ExpansionPanelRow>
                <DynamicTableCell style={{textAlign: "center"}}>
                    <ReportDownloadLink disabled={!isDownloadable} taskId={task.id}
                                        executive reportId={latestReportId} latestReportDate={lastScan}
                                        lastTaskExecutionId={lastTaskExecutionId} isDownloadable={isDownloadable}/>
                </DynamicTableCell>
                <DynamicTableCell style={{textAlign: "center"}}>

                    <ReportDownloadLink disabled={!isDownloadable} taskId={task.id}
                                        latestReportDate={lastScan} reportId={latestReportId}
                                        lastTaskExecutionId={lastTaskExecutionId} isDownloadable={isDownloadable}/>
                </DynamicTableCell>
            </DynamicTableRow>
        )
    }

}

_DashboardTaskEntry.contextType = TaskContext

function mapDispatchToProps(dispatch) {
    const actions = bindActionCreators({
        openAlertDialog: openAlertDialog,
        openErrorSnackbar: openErrorSnackbar
    }, dispatch)
    return {...actions, dispatch}
}

function mapStateToProps(state) {
    return {}
}

export const DashboardTaskEntry = compose(
    withStyles(styles),
    withTheme,
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(_DashboardTaskEntry)
