import {
    siUbuntu,
    siDebian,
    siCentos,
    siHp,
    siRedhat,
    siLinux,
    siSuse,
    siHuawei,
    siFreebsd,
    siKyocera,
    siSonicwall,
    siNetapp,
    siFortinet,
    siOpenbsd,
    siAppletv,
    siIos,
    siMacos,
    siPaloaltosoftware, siCitrix, siCisco, siSynology, siVmware
} from "simple-icons"
import {OPERATINGSYSTEM} from "./RegexOSMap"
import Greenbone from "../../../../assets/greenbone2.svg"
import WindowsXPLogo from "../../../../assets/icons/os/windowsxp.svg"
import WindowsLogo from "../../../../assets/icons/os/windows.svg"
import IbmLogo from "../../../../assets/icons/os/ibm.svg"

function GetSimpleIcon(os) {
    return SimpleIcons.get(os).svg
}

export function OSIconMapper(OS) {
    switch (OS) {
        case OPERATINGSYSTEM.WINDOWS:
            return WindowsLogo
        case OPERATINGSYSTEM.WINDOWS_OLD:
            return WindowsXPLogo
        case OPERATINGSYSTEM.UBUNTU:
            return siUbuntu.svg
        case OPERATINGSYSTEM.DEBIAN:
            return siDebian.svg
        case OPERATINGSYSTEM.CENTOS:
            return siCentos.svg
        case OPERATINGSYSTEM.HP:
            return siHp.svg
        case OPERATINGSYSTEM.REDHAT:
            return siRedhat.svg
        case OPERATINGSYSTEM.LINUX:
            return siLinux.svg
        case OPERATINGSYSTEM.SUSE:
            return siSuse.svg
        case OPERATINGSYSTEM.HUAWEI:
            return siHuawei.svg
        case OPERATINGSYSTEM.FREEBSD:
            return siFreebsd.svg
        case OPERATINGSYSTEM.IBM:
            return IbmLogo
        case OPERATINGSYSTEM.KYOCERA:
            return siKyocera.svg
        case OPERATINGSYSTEM.SONICWALL:
            return siSonicwall.svg
        case OPERATINGSYSTEM.NETAPP:
            return siNetapp.svg
        case OPERATINGSYSTEM.FORTI:
            return siFortinet.svg
        case OPERATINGSYSTEM.PALOALTO:
            return siPaloaltosoftware.svg
        case OPERATINGSYSTEM.CITRIX:
            return siCitrix.svg
        case OPERATINGSYSTEM.CISCO:
            return siCisco.svg
        case OPERATINGSYSTEM.SYNOLOGY:
            return siSynology.svg
        case OPERATINGSYSTEM.VMWARE:
            return siVmware.svg
        case OPERATINGSYSTEM.MACOS:
            return siMacos.svg
        case OPERATINGSYSTEM.IOS:
            return siIos.svg
        case OPERATINGSYSTEM.APPLETV:
            return siAppletv.svg
        case OPERATINGSYSTEM.OPENBSD:
            return siOpenbsd.svg
        case OPERATINGSYSTEM.GREENBONE:
            return Greenbone
    }
}
